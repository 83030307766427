$color-main: #1BD96A
$gray: #A9A9A9
$color-bg: #1B1E2A

*
  margin: 0
  padding: 0
  box-sizing: border-box
  font-family: 'Inter', sans-serif
  font-weight: 400

body, html, #root
  background: $color-bg
  overflow: hidden

#root
  width: 100%
  height: 100vh
  background: url(../../public/static/images/background/bg.webp)
  background-position: center
  background-repeat: no-repeat
  background-size: cover

  .home-centered
    width: 100%
    height: 100%
    padding: 46px
    display: flex
    justify-content: center
    align-items: center

    @media only screen and (max-width: 1023px)
      padding: 38px

    @media only screen and (max-width: 559px)
      padding: 20px

    .title-centered
      width: 100%

      .header
        text-align: center

        img
          max-width: 284px
          width: 100%

        h1
          font-size: 84px
          color: #fff
          font-weight: 600

          @media only screen and (max-width: 1023px)
            font-size: 78px

          @media only screen and (max-width: 559px)
            font-size: 60px

          span
            color: $color-main
            font-weight: bold

      .main
        width: 100%
        min-height: 12pxpx
        margin-top: 18px
        margin-bottom: 50px

        p
          color: $gray
          font-size: 24px
          text-align: center

      .footer
        display: flex
        justify-content: center
        align-items: center
        flex-wrap: wrap
        gap: 24px

        .button
          min-width: 135px
          text-decoration: none
          height: 44px
          background: $color-main
          outline: none
          border: none
          border-radius: 16px
          cursor: pointer
          color: $color-bg
          transition: all .1s linear

          &:active
            scale: .92

          &:hover, &:active
            background: lighten($color-main, 10%)

          &.secondary
            background: transparent
            border: 2px solid $gray
            color: $gray

            &:hover, &:active
              color: lighten($gray, 10%)
              border-color: lighten($gray, 10%)

          .button-content
            width: 100%
            height: 100%
            padding: 11px 23px
            display: flex
            justify-content: center
            align-items: center
            gap: 12px
            font-size: 16px
            font-weight: bold

            img
              width: 26px
